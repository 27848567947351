<template>
  <div>
    <v-row class="ma-4">
      <v-col>
        <div class="text-h4">
          Course Blender
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-4">
      <v-col cols="4">
        <course-selector/>
      </v-col>
      <v-col cols="4">
        <v-card class="mb-4">
          <v-card-title>
            Blended Course
          </v-card-title>
          <v-card-subtitle>
            Course Information
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col>
                <b>Total Duration:</b> {{ getCourseTime() }} mins
                <br>
                <b>Days:</b> {{ (getCourseTime() / 360).toFixed(2) }}
                <br>
                <b>Labs:</b> {{ getTotalLabs() }}
              </v-col>
              <v-col>
                <v-btn
                    right
                    v-if="selectedModules.length > 0"
                    :href="genCSVURL(selectedModules)"
                    target="_blank"
                    class="success mx-2"
                >Tailored Output</v-btn>
                <v-btn
                    v-if="selectedModules.length > 0"
                    :href="genPDFURL(selectedModules)"
                    target="_blank"
                    class="success mx-2"
                >Data Sheet</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-list class="py-0">
          <vuedraggable
              handle=".handle"
              style="width: 100%"
              :list="selectedModules"
          >
            <transition-group
                type="transition"
                name="flip-list"
            >
              <module-panel
                  v-for="(module, index) in Object.values(selectedModules)"
                  v-bind:key="`course-binder-` + index"
                  :module="module"
                  :position="index"
              />
            </transition-group>
          </vuedraggable>
        </v-list>
      </v-col>
      <v-col cols="4">
        <course-selector/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CourseSelector from "@/components/CourseSelector";
import {mapGetters, mapState} from "vuex";
import ModulePanel from "@/components/ModulePanel";
import vuedraggable from "vuedraggable";

export default {
  name: "Tamale",
  components: {ModulePanel, CourseSelector, vuedraggable},
  methods: {
    ...mapGetters('Application', [
      'getUnselectedCourses',
      'getCourseTime',
      'getTotalLabs'
    ]),
    genCSVURL(modules){
      return 'https://d318rb7mh14n67.cloudfront.net/generate_csv?outline=' + Buffer.from(JSON.stringify(modules)).toString('base64')
    },
    genPDFURL(modules){
      return 'https://d318rb7mh14n67.cloudfront.net/generate_pdf?outline=' + Buffer.from(JSON.stringify(modules)).toString('base64')
    }
  },
  computed: {
    ...mapState('Application', [
      'courses',
      'selectedModules',
      'modelPosition'
    ])
  }
}
</script>

<style scoped>

</style>
