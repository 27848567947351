<template>
  <v-app>
    <v-app-bar
      app
    >
      <v-app-bar-nav-icon
          class="mx-1"
          @click="toggleNavigation()"
      />
      <v-spacer/>
      <v-app-bar-title>
        AWS TC TAMALE
      </v-app-bar-title>
      <v-spacer/>
      <v-btn
          @click="toggleTheme()"
      >
      <v-icon left>
        fa-solid fa-circle-half-stroke
      </v-icon>
        <div v-if="isDark()">
          Dark Mode
        </div>
        <div v-else>
          Light Mode
        </div>
      </v-btn>

    </v-app-bar>

    <v-navigation-drawer
        app
        v-model="showNavigation"
        @click.stop="toggleNavigation()"
  >

    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Template",
  computed: {
    ...mapState("Application", [
        'showNavigation'
    ])
  },
  methods: {
    ...mapActions("Application", [
        'toggleNavigation'
    ]),
    isDark() {
      return(this.$vuetify.theme.dark)
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  }
}
</script>

<style scoped>

</style>
