import Vue from 'vue'

function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

Array.prototype.doesKeyMatch = function(key, value){
    for(var i = 0; i < this.length;)
        if(this[i++][key] === value) return true
    return false
}

Array.prototype.getByID = function(value){
    for(var i = 0; i < this.length;)
        if(this[i].id === value) return this[i].id
        i++
    return false
}

function uuid() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const initialState = () => ({
    selectedCourses: {},
    selectedModules: [],
    showNavigation: false,
    modelPosition: 0,
    courses: [
        {
            id: uuid(),
            title: "Developing on AWS",
            version: "v4",
            modules: [
                {
                    id: uuid(),
                    title: "Module 1: Course Overview",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 25,
                    slides: 0,
                    topics: [
                        {
                            title: "Logistics",
                            section: "1.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Student resources",
                            section: "1.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Agenda",
                            section: "1.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Introductions",
                            section: "1.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 2: Building a Web Application on AWS",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 15,
                    slides: 0,
                    topics: [
                        {
                            title: "Discuss the architecture of the application you are going to build during this course",
                            section: "2.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explore the AWS services needed to build your web application",
                            section: "2.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Discover how to store, manage, and host your web application",
                            section: "2.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 3: Getting Started with Development on AWS",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 55,
                    slides: 0,
                    topics: [
                        {
                            title: "Describe how to access AWS services programmatically",
                            section: "3.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "List some programmatic patterns and how they provide efficiencies within AWS SDKs and AWS CLI",
                            section: "3.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explain the value of AWS Cloud9",
                            section: "3.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 4: Getting Started with Permissions",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 45,
                    slides: 0,
                    topics: [
                        {
                            title: "Review AWS Identity and Access Management (IAM) features and components permissions to support a development environment",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate how to test AWS IAM permissions",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Configure your IDEs and SDKs to support a development environment",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate accessing AWS services using SDKs and AWS Cloud9",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 1: Configure the Developer Environment",
                    course: "Developing on AWS",
                    type: "lab",
                    duration: 60,
                    slides: 0,
                    topics: [],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 5: Getting Started with Storage",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 45,
                    slides: 0,
                    topics: [
                        {
                            title: "Describe the basic concepts of Amazon S3",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "List the options for securing data using Amazon S3",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explain how to connect to the Amazon S3 service",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Describe request and response objects",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 6: Processing Your Storage Operations",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 60,
                    slides: 0,
                    topics: [
                        {
                            title: "Perform key bucket and object operations",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explain how to handle multiple and large objects",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Create and configure an Amazon S3 bucket to host a static website",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Grant temporary access to your objects",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate performing Amazon S3 operations using SDKs",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 2: Develop Solutions Using Amazon S3",
                    course: "Developing on AWS",
                    type: "lab",
                    duration: 60,
                    slides: 0,
                    topics: [],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 7: Getting Started with Databases",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 40,
                    slides: 0,
                    topics: [
                        {
                            title: "Describe the key components of DynamoDB",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explain how to connect to DynamoDB",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Describe how to build a request object",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explain how to read a response object",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "List the most common troubleshooting exceptions",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 8: Processing Your Database Operations",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 60,
                    slides: 0,
                    topics: [
                        {
                            title: "Develop programs to interact with DynamoDB using AWS SDKs",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Perform CRUD operations to access tables, indexes, and data",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Describe developer best practices when accessing DynamoDB",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Review caching options for DynamoDB to improve performance",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Perform DynamoDB operations using SDK",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 3: Develop Solutions Using Amazon DynamoDB",
                    course: "Developing on AWS",
                    type: "lab",
                    duration: 60,
                    slides: 0,
                    topics: [],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 9: Processing Your Application Logic",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 75,
                    slides: 0,
                    topics: [
                        {
                            title: "Develop a Lambda function using SDKs",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Configure triggers and permissions for Lambda functions",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Test, deploy, and monitor Lambda functions",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 4: Develop Solutions Using AWS Lambda Functions",
                    course: "Developing on AWS",
                    type: "lab",
                    duration: 60,
                    slides: 0,
                    topics: [],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 10: Managing the APIs",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 45,
                    slides: 0,
                    topics: [
                        {
                            title: "Describe the key components of API Gateway",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Develop API Gateway resources to integrate with AWS services",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Configure API request and response calls for your application endpoints",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Test API resources and deploy your application API endpoint",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate creating API Gateway resources to interact with your application APIs",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 5: Develop Solutions Using Amazon API Gateway",
                    course: "Developing on AWS",
                    type: "lab",
                    duration: 60,
                    slides: 0,
                    topics: [],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 11: Building a Modern Application",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 45,
                    slides: 0,
                    topics: [
                        {
                            title: "Describe the challenges with traditional architectures",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Describe the microservice architecture and benefits",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explain various approaches for designing microservice applications",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explain steps involved in decoupling monolithic applications",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate the orchestration of Lambda Functions using AWS Step Functions",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 12: Granting Access to Your Application Users",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 45,
                    slides: 0,
                    topics: [
                        {
                            title: "Analyze the evolution of security protocols",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Explore the authentication process using Amazon Cognito",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Manage user access and authorize serverless APIs",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Observe best practices for implementing Amazon Cognito",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate the integration of Amazon Cognito and review JWT tokens",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 6: Capstone – Complete the Application Build",
                    course: "Developing on AWS",
                    type: "lab",
                    duration: 90,
                    slides: 0,
                    topics: [],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 13: Deploying Your Application",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 30,
                    slides: 0,
                    topics: [
                        {
                            title: "Identify risks associated with traditional software development practices",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Understand DevOps methodology",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Configure an AWS SAM template to deploy a serverless application",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Describe various application deployment strategies",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate deploying a serverless application using AWS SAM",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 14: Observing Your Application",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 45,
                    slides: 0,
                    topics: [
                        {
                            title: "Differentiate between monitoring and observability",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Evaluate why observability is necessary in modern development and key components",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Understand CloudWatch’s part in configuring the observability",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate using CloudWatch Application Insights to monitor applications",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Demonstrate using X-Ray to debug your applications",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    labs: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 7: Observe the Application Using AWS X-Ray",
                    course: "Developing on AWS",
                    type: "lab",
                    duration: 60,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 15: Course Wrap-up",
                    course: "Developing on AWS",
                    type: "module",
                    duration: 15,
                    slides: 0,
                    topics: [
                        {
                            title: "Course overview",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "AWS training courses",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Certifications",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Course feedback",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    labs: [],
                    services: []
                }
            ]
        },
        {
            id: uuid(),
            title: "Architecting on AWS",
            version: "v7",
            modules: [
                {
                    id: uuid(),
                    title: "Module 0: Introductions & Course Map review",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 35,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 1: Architecting Fundamentals Review",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 45,
                    slides: 0,
                    topics: [
                        {
                            title: "AWS Services and Infrastructure",
                            section: "1.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Infrastructure Models",
                            section: "1.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "AWS API Tools",
                            section: "1.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Securing your infrastructure",
                            section: "1.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "The Well-Architected Framework",
                            section: "1.5",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 1: Deploy an EC2 Instance",
                    course: "Architecting on AWS",
                    type: "lab",
                    duration: 45,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 2: Account Security",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 65,
                    slides: 0,
                    topics: [
                        {
                            title: "Security Principals",
                            section: "2.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Identity and Resource-Based Policies",
                            section: "2.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Account Federation",
                            section: "2.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Introduction to Managing Multiple Accounts",
                            section: "2.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 3: Networking, Part 1",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 65,
                    slides: 0,
                    topics: [
                        {
                            title: "IP Addressing",
                            section: "3.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Amazon Virtual Private Cloud (VPC), Patterns and Quotas",
                            section: "3.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Routing",
                            section: "3.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Internet Access",
                            section: "3.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Network Access Control Lists (NACLs)",
                            section: "3.5",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Security Groups",
                            section: "3.5",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 4: Compute",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 75,
                    slides: 0,
                    topics: [
                        {
                            title: "Amazon Elastic Cloud Compute (EC2)",
                            section: "4.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "EC2 Instances and Instance Selection",
                            section: "4.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "High Performance Computing on AWS",
                            section: "4.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Lambda and EC2, When to Use Which",
                            section: "4.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 2: Build Your Amazon VPC Infrastructure",
                    course: "Architecting on AWS",
                    type: "lab",
                    duration: 60,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 5: Storage",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 90,
                    slides: 0,
                    topics: [
                        {
                            title: "Amazon S3, Security, Versioning and Storage Classes",
                            section: "5.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Shared File Systems",
                            section: "5.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Data Migration Tools",
                            section: "5.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 6: Database Services",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 70,
                    slides: 0,
                    topics: [
                        {
                            title: "AWS Database Solutions",
                            section: "6.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Amazon Relational Database Services (RDS)",
                            section: "6.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "DynamoDB, Features and Use Cases",
                            section: "6.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Redshift, Features, Use Cases and Comparison with RDS",
                            section: "6.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Caching and Migrating Data",
                            section: "6.5",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 3: Create a Database Layer in Your Amazon VPC Infrastructure",
                    course: "Architecting on AWS",
                    type: "lab",
                    duration: 30,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 7: Monitoring and Scaling",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 60,
                    slides: 0,
                    topics: [
                        {
                            title: "Monitoring: CloudWatch, CloudTrail, and VPC Flow Logs",
                            section: "7.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Invoking Events",
                            section: "7.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Elastic Load Balancing",
                            section: "7.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Auto Scaling Options and Monitoring Cost",
                            section: "7.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 4: Configure High Availability in Your Amazon VPC",
                    course: "Architecting on AWS",
                    type: "lab",
                    duration: 30,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 8: Automation",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 30,
                    slides: 0,
                    topics: [
                        {
                            title: "CloudFormation",
                            section: "8.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "AWS Systems Manager",
                            section: "8.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 9: Containers",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 40,
                    slides: 0,
                    topics: [
                        {
                            title: "Microservices",
                            section: "9.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Monitoring Microservices with X-Ray",
                            section: "9.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Containers",
                            section: "9.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 10: Networking Part 2",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 60,
                    slides: 0,
                    topics: [
                        {
                            title: "VPC Peering & Endpoints",
                            section: "10.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Transit Gateway",
                            section: "10.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Hybrid Networking",
                            section: "10.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Route 53",
                            section: "10.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 11: Serverless Architecture",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 50,
                    slides: 0,
                    topics: [
                        {
                            title: "Amazon API Gateway",
                            section: "11.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Amazon SQS, Amazon SNS",
                            section: "11.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Amazon Kinesis Data Streams & Kinesis Firehose",
                            section: "11.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Step Functions",
                            section: "11.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 5: Build a Serverless Architecture",
                    course: "Architecting on AWS",
                    type: "lab",
                    duration: 45,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 12: Edge Services",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 50,
                    slides: 0,
                    topics: [
                        {
                            title: "Edge Fundamentals",
                            section: "12.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Amazon CloudFront",
                            section: "12.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "AWS Global Accelerator",
                            section: "12.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "AWS Web Application Firewall (WAF), DDoS and Firewall Manager",
                            section: "12.4",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "AWS Outposts",
                            section: "12.5",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 6: Configure an Amazon CloudFront Distribution with an Amazon S3 Origin",
                    course: "Architecting on AWS",
                    type: "lab",
                    duration: 45,
                    slides: 0,
                    topics: [],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Module 13: Backup and Recovery",
                    course: "Architecting on AWS",
                    type: "module",
                    duration: 50,
                    slides: 0,
                    topics: [
                        {
                            title: "Planning for Disaster Recovery",
                            section: "13.1",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "AWS Backup",
                            section: "13.2",
                            duration: 0,
                            slides: 0,
                            services: []
                        },
                        {
                            title: "Recovery Strategies",
                            section: "13.3",
                            duration: 0,
                            slides: 0,
                            services: []
                        }
                    ],
                    services: []
                },
                {
                    id: uuid(),
                    title: "Lab 7: Build an AWS Multi-Tier Architecture",
                    course: "Architecting on AWS",
                    type: "lab",
                    duration: 90,
                    slides: 0,
                    topics: [],
                    services: []
                },

            ]
        }
    ]
});

const state = initialState()

const actions = {
    toggleNavigation({ commit }) {
        commit('toggleNavigationDrawer')
    },
    toggleCourse({ commit }, courseID) {
        commit('toggleSelectedCourse', courseID)
    },
    toggleModule({ commit }, module) {
        commit('toggleSelectedModule', module)
    },
    moveToPosition({ commit }, payload) {
        commit('moveSelectedModule', payload)
    }
}

const getters = {
    getUnselectedCourses(state) {
        let out = []
        let usedIDs = []

        Object.keys(state.courses).map(courseID => {
            if(usedIDs.includes(courseID)) return
            out.push(state.courses[courseID])
        })

        return out
    },
    isSelectedModule: (state) => (id) => {
        let out = false

        state.selectedModules.map(modules => {
            if(modules.id === id) {
                out = true;
            }
        })

        return out
    },
    getCourseByID: (state) => (id) => {
        let res = {};

        state.courses.map(course => {
            if(course.id.toString() === id.toString()) {
                res = course
            }
        })

        return res
    },
    getTotalLabs(state) {
        let labCount = 0

        state.selectedModules.map(module => {
            labCount += module.type === 'lab' ? 1 : 0
        })

        return labCount
    },
    getCourseTime(state) {
        let totalTime = 0

        state.selectedModules.map(module => {
            totalTime += module.duration
        })

        return totalTime
    },
}

const mutations = {
    toggleNavigationDrawer(state) {
        state.showNavigation = !state.showNavigation
    },
    toggleSelectedModule(state, module) {
        if(state.selectedModules.doesKeyMatch('id', module.id)) {
            state.selectedModules = state.selectedModules.reduce(((previousValue, currentValue) => {
                if (currentValue.id !== module.id) previousValue.push(currentValue)
                return previousValue
            }), [])
        } else {
            state.selectedModules.push(module)
        }

        state.modelPosition = state.selectedModules.length-1
    },
    toggleSelectedCourse(state, courseID) {
        if(courseID === "" || courseID === undefined || courseID === null) return

        // If the user changes the course after choosing two, the selected modules will reset
        if(state.selectedCourses.length === 2) {
            state.selectedModules = {}
        }

        if(!Object.keys(state.selectedCourses).includes(courseID)) {
            Vue.set(state.selectedCourses, courseID, true)
        } else {
            Vue.delete(state.selectedCourses, courseID)
        }
    },
    moveSelectedModule(state, payload) {
        state.selectedModules = array_move(state.selectedModules, payload.oldIndex, payload.newIndex)
        if(payload.oldIndex !== state.modelPosition) {
            state.modelPosition = payload.oldIndex
        }

        state.modelPosition += payload.newIndex - payload.oldIndex
        if(state.modelPosition < 0) {
            state.modelPosition = 0
        }

        if(state.modelPosition > state.selectedModules.length-1) {
            state.modelPosition = state.selectedModules.length-1
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
