<template>
  <div>
  <v-list-item>
    <div>
      <v-icon
          small
          left
          class="handle"
          v-if="!blended"
      >
        fa-solid
        fa-arrows-alt
      </v-icon>
      <v-icon
          small
          left
          v-if="this.isSelectedModule()(module.id) && blended"
          color="green"
      >
        fa-solid
        fa-circle-check
      </v-icon>
      <v-icon
          small
          v-if="module.type === 'lab'"
          left
      >
        fa-solid
        fa-flask
      </v-icon>
      {{ module.title }}
    </div>
    <v-spacer/>
    <span class="text-right">{{ module.duration }} mins</span>
    <v-btn
      fab
      x-small
      v-if="!this.isSelectedModule()(module.id)"
      color="primary"
      class="mx-2"
      @click="toggleModule(module)"
    >
      <v-icon small>fa-solid fa-plus</v-icon>
    </v-btn>
    <v-btn
        fab
        x-small
        v-if="this.isSelectedModule()(module.id)"
        color="error"
        class="mx-2"
        @click="toggleModule(module)"
    >
      <v-icon small>fa-solid fa-times</v-icon>
    </v-btn>
  </v-list-item>
    <v-divider/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "ModulePanel",
  props: {
    module: {
      required: true
    },
    position: {
      required: true
    },
    blended: {
      default: false
    }
  },
  computed: {
    ...mapState('Application', ['selectedModules'])
  },
  methods: {
    ...mapGetters('Application', ['isSelectedModule']),
    ...mapActions('Application', [
      'toggleModule',
      'moveToPosition'
    ])
  }
}
</script>

<style scoped>

</style>
