<template>
  <div>
    <v-row>
      <v-col class="mb-0 pb-0">
        <v-select
            label="Select Course"
            v-model="course"
            :items="courses"
            item-text="title"
            item-value="id"
            outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="courseID !== 0" class="mt-0 pt-0">
        <div class="title">Modules</div>
        <v-list class="py-0">
            <module-panel
                v-for="module in this.getCourseByID()(courseID).modules"
                v-bind:key="`course-` + module"
                :module="module"
                :position="index"
                blended="true"
            />
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ModulePanel from "@/components/ModulePanel";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "CourseSelector",
  components: {ModulePanel},
  data() {
    return {
      courseID: 0
    }
  },
  computed: {
    ...mapState('Application', ['courses', 'selectedModules']),
    course: {
      get: function () {
        if (Object.keys(this.courses).includes(this.courseID)) {
          return this.courses[this.courseID].title
        }
        return null
      },
      set: function (current) {
        this.courseID = current
      }
    }
  },
  methods: {
    ...mapGetters('Application', [
      'getUnselectedCourses',
      'getCourseByID',
      'isSelectedModule'
    ]),
    ...mapActions('Application', ['toggleCourse'])
  },
}
</script>

<style scoped>

</style>
