import Vue from 'vue'
import VueRouter from 'vue-router'
import Tamale from "@/views/pages/Tamale";
import CreateCourse from "@/views/pages/CreateCourse";
import Report from "@/views/pages/Report";
import Template from "@/views/Template";

Vue.use(VueRouter)

const routes = [
  {
    path: "",
    name: '',
    component: Template,
    children: [
      {
        path: '/',
        name: "Home",
        component: Tamale
      },
      {
        path: '/new',
        name: 'COURSE_POST',
        component: CreateCourse
      },
    ]
  },
  {
    path: "/report",
    name: "REPORT",
    component: Report
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
