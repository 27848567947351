<template>
  <v-app>
    <div>
      <v-img src="header.png" height="200px">
        <v-container class="mt-9">
          <h4 class="text-h4 white--text">{{ data.title }}</h4>
          <h5 class="text-h5 white--text">{{ data.subtitle }}</h5>
        </v-container>
      </v-img>
      <v-container>
        <v-row>
          <v-col>
            <div class="title text-h5">Course description</div>
            <div class="subtitle-1 text-justify mt-2">
              Architecting on AWS is for solutions architects, solution-design engineers, and developers seeking an understanding of AWS architecting. In this course, you will learn to identify services and features to build resilient, secure and highly available IT solutions on the AWS Cloud.
              Architectural solutions differ depending on industry, types of applications, and business size. AWS Authorized Instructors emphasize best practices using the AWS Well-Architected Framework, and guide you through the process of designing optimal IT solutions, based on real-life scenarios. The modules focus on account security, networking, compute, storage, databases, monitoring, automation, containers, serverless architecture, edge services, and backup and recovery. At the end of the course, you will practice building a solution and apply what you have learned with confidence.
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="title text-h5">Activities</div>
            This course includes presentations based on use cases, group discussions, demonstrations, assessments, and hands-on labs.
          </v-col>
        </v-row>
      </v-container>
    </div>

  </v-app>
</template>

<script>
export default {
  name: "Report",
  props: {
    data: {
      default: {
        title   : "Developing on AWS",
        subtitle: "AWS Course Content"
      }
    }
  }
}
</script>

<style scoped>

</style>
