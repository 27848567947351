<template>
  <v-container class="pt-4">
    <v-row>
      <h1 class="title">Course</h1>
    </v-row>
    <v-row>
      <v-col md="6" sm="12">
        <v-text-field
            outlined
            background-color="white"
            label="Title"
        />
      </v-col>
      <v-col md="6" sm="12">
        <v-text-field
            outlined
            background-color="white"
            label="Version"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="title">Modules ({{ course.modules.length }})</h1>
        <v-spacer/>
        <v-btn fab x-small color="success" class="mx-2"
               @click="course.modules.push({title: '', duration: 0, topics: [], labs: []})">
          <v-icon>fa-solid fa-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel v-for="(_, i) in course.modules.length" v-bind:key="i">
            <v-expansion-panel-header class="subtitle-2">
              {{ course.modules[i].title }}
              <v-spacer/>
              {{ course.modules[i].duration }} minutes
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn fab x-small color="error" class="mx-2" @click="course.modules.splice(i, 1)">
                <v-icon>fa-solid fa-minus</v-icon>
              </v-btn>
              <v-text-field
                  label="Title"
                  v-model="course.modules[i].title"
              />
              <div class="title">Objectives
                <v-spacer/>
                <v-btn fab x-small color="success" class="mx-2"
                       @click="course.modules[i].topics.push({title: '', duration: 0})">
                  <v-icon>fa-solid fa-plus</v-icon>
                </v-btn>
              </div>
              <v-expansion-panels>
                <draggable
                    :list="course.modules[i].topics"
                    class="list-group"
                >
                  <div
                      v-for="(topic, topicIndex) in course.modules[i].topics.length"
                      v-bind:key="topicIndex"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="subtitle-2">
                        {{ course.modules[i].topics[topicIndex].title }}
                        <v-spacer/>
                        {{ course.modules[i].topics[topicIndex].duration }} minutes
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field v-model="course.modules[i].topics[topicIndex].title" label="Title"/>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>
                  <div
                      class="list-group-item"
                      v-for="element in list"
                      :key="element.name"
                  >
                    {{ element.name }}
                  </div>
                </draggable>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CreateCourse",
  methods: {
    arrayAdd(key, value = {}, index = -1) {
      if (index >= 0) {
        return this.course
      }

      this.course[key].push(value)
    },
    arrayRemove(key, index) {
      this.course[key].splice(index, 1)
    }
  },
  data() {
    return {
      modulesCount: 1,
      course: {
        id: "",
        title: "",
        version: "",
        modules: [
          {
            title: "",
            duration: 0,
            topics: [
              {
                title: "",
                duration: 0
              }
            ]
          }
        ],
        labs: [
          {
            title: "",
            duration: 0
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>

